<template>
  <section>
    <div class="flex flex-no-wrap">
      <Nav />

      <section class="enterprise-setup h-100 p-3 pb-5">

        <div class="flex mb-2">
          <div class="flex-grow">
            <div class="container p-2">
              <div class="row">
                <div class="col-12 text-start">
                  <input
                    v-model="email"
                    type="text"
                    class="form-control input-search"
                    placeholder="Search"
                    required
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="flex-initial">
            <div class="container p-2">
              <div class="row">
                <div class="col-12 text-start">
                  <img
                    src="@/assets/images/lisa_valdez_picture.png"
                    alt="Public Profile Picture"
                    style="max-height: 500px;"
                  >
                </div>
              </div>
            </div>

            <div class="container p-2">
              <div class="row">
                <div class="col-12 text-start">
                  <h5 class="text align-self-center me-auto fw-light">
                    Shared Interests
                  </h5>

                  <div class="mt-3 mb-3">
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="container p-2">
              <div class="row">
                <div class="col-12 text-start">
                  <h5 class="text align-self-center me-auto fw-light">
                    Shared Skills
                  </h5>

                  <div class="mt-3 mb-3">
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="container p-2">
              <div class="row">
                <div class="col-12 text-start">
                  <h5 class="text align-self-center me-auto fw-light">
                    Mutual Contacts
                  </h5>
                </div>
              </div>
            </div>

          </div>

          <div class="flex-initial">

            <div class="container container-card p-2" style="padding: 20px 0!important;">
              <div class="row">
                <div class="col-8 text-start">
                  <ul class="social-icons">
                    <li>
                      <img
                        src="@/assets/images/social/ic_linkedin_original.svg"
                        class="logo"
                        alt="LinkedIn"
                      >
                    </li>

                    <li>
                      <img
                        src="@/assets/images/social/ic_facebook_original.svg"
                        class="logo"
                        alt="Facebook"
                      >
                    </li>

                    <li>
                      <img
                        src="@/assets/images/social/ic_twitter_original.svg"
                        class="logo"
                        alt="Twitter"
                      >
                    </li>

                    <li>
                      <img
                        src="@/assets/images/social/ic_google_original.svg"
                        class="logo"
                        alt="Google"
                      >
                    </li>
                  </ul>
                </div>

                <div class="col-4 text-end">
                  <a class="btn btn-secondary-outline me-4" style="margin-top: 3px;">Follow</a>
                </div>
              </div>
            </div>

            <div class="container container-card p-2">
              <div class="row">
                <div class="col-12 text-start">
                  <h5 class="text align-self-center me-auto fw-light">
                    About Lisa
                  </h5>

                  <p>I am the lead business development advisor with the Goldman Sachs Wealth Management Team, an advisory practice servicing the needs of ultra-high net worth families for many years, and focus on assets allocation, values-based wealth management planning and portfolio construction. I started my career in Real Estate Finance, focusing on finding lending solutions for real estate investors and home owners. Prior to my career in finance, I played professional basketball in Asia, Europe, South America, The Middle East, as well as here in the states. I earned my B.A. in Political Science with a minor in Business Administration from The University of Oregon. I currently reside in my hometown of Hermosa Beach, where my brother and I run a youth club basketball program.</p>

                  <p>Asset Allocation does not ensure a profit or protect against loss in declining markets.</p>
                </div>
              </div>
            </div>

            <div class="container container-card p-2">
              <div class="row">
                <div class="col-6 text-start">
                  <h5 class="text align-self-center me-auto fw-light">
                    Skills
                  </h5>

                  <div class="mt-3 mb-3">
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                  </div>
                </div>

                <div class="col-6 text-start">
                  <h5 class="text align-self-center me-auto fw-light">
                    Interests
                  </h5>

                  <div class="mt-3 mb-3">
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                    <span class="tag">Extrovert</span>
                    <span class="tag">Risk Taker</span>
                    <span class="tag">Passionate</span>
                    <span class="tag">Globe Trotter</span>
                    <span class="tag">MacGyver</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="container container-card p-2">
              <div class="row">
                <div class="col-12 text-start">
                  <h5 class="text align-self-center me-auto fw-light">
                    Experience
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Nav from '@/components/nav/nav.vue';
export default {
  name: 'PublicProfile',
  components: {
    Nav
  }
}
</script>

<style scoped src="@/css/bootstrap.min.css"></style>

<style scoped>
.enterprise-setup {
  background-color: #EDEDED;
}
.enterprise-setup h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.enterprise-setup h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #142328;
  letter-spacing: 1px;
}
.enterprise-setup h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-top: 20px;
  max-height: 30px;
}
.enterprise-setup .container:first-child > .d-flex {
  border-bottom: 0.5px solid #332C54;
}
.logo {
  max-height: 33px!important;
}
.container-card {
  padding: 40px 40px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
button {
  background-color: #FFFFFF!important;
  border: 1px solid #83C03B;
  box-sizing: border-box;
  border-radius: 30px;
  display: inline-block;
}
button:hover {
  color: #83C03B!important;
  border: 1px solid #83C03B!important;
}
a {
  text-decoration: none;
}
.tag {
  display: inline-block;
  padding: 5px;
  background: rgba(3, 29, 90, 0.2);
  border-radius: 3px;
  margin: 0px 5px;
  color: #1E1E1E;
  font-size: 14px;
  margin-bottom: 10px;
}
.tag.active {
  background: #83C03B;
  color: #F9F9F9;
}
.social-icons {
  margin-bottom: 0;
  margin-top: 5px;
}
.social-icons li:first-child {
  margin-left: 0px;
}
.social-icons li {
  display: inline-block;
  margin-left: 20px;
}
.btn-secondary {
  border-radius: 99px;
  background-color: rgb(131, 192, 59);
  border-color: rgb(131, 192, 59);
  color: #FFFFFF;
}
.btn-secondary-outline {
  border-radius: 99px;
  border-color: rgb(131, 192, 59);
  color: rgb(131, 192, 59);
}
.input-search {
  background: #FFFFFF;
  border: 1px solid rgba(3, 29, 90, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}
</style>
